import React from "react";
import CommonLayout from "../Share/CommonLayout";
import TopNotice from "../components/TopNotice";

function ProjectLayout(props) {
   return (
      <CommonLayout>
         <TopNotice className="pb-3"></TopNotice>
         <div>{props.children}</div>
      </CommonLayout>
   );
}

export default ProjectLayout;
