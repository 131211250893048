import { createStyles, makeStyles, TextField, Typography, Button } from "@material-ui/core";
import React, { useState } from "react";
import { useUserDispatch } from "../systems/UserContext";
import "../Styles/Login.css";
import UserService from "../systems/UserService";
import { ErrorHandler } from "../systems/HttpClient";
import { useHistory } from "react-router-dom";
import useQuery from "../systems/useQuery";
import logo from "../images/logo.png";

const useStyles = makeStyles((theme) =>
   createStyles({
      top: {
         width: "100%",
         marginTop: theme.spacing(2),
      },
      bottom: {
         width: "100%",
         marginTop: theme.spacing(2),
         marginBottom: theme.spacing(2),
      },
      button: {
         width: "100%",
         padding: theme.spacing(1.5),
         fontSize: "1.0rem",
      },
      sessionOut: {
         whiteSpace: "pre-line",
         color: "red",
         fontSize: "0.9rem",
      },
   })
);

function Login() {
   const dispatch = useUserDispatch();
   const [state, setState] = useState({ userId: "", userPw: "" });
   const classes = useStyles();
   const history = useHistory();
   const query = useQuery();

   const handleSubmit = React.useCallback(
      function () {
         UserService.Login(state.userId, state.userPw)
            .then((response) => {
               const { user, token } = response.data;
               UserService.SetToken(token);
               dispatch({ type: "LOGIN", item: user });
               //성공했을 때, 가야하는 곳
               history.push("/list");
            })
            .catch((error) => {
               let msg = ErrorHandler(error);
               if (error.response) {
                  switch (error.response.status) {
                     case 403: //forbidden
                     case 404:
                        msg = "로그인 정보가 틀리거나 회원정보가 없습니다.";
                        break;
                     default:
                        break; //noting to do
                  }
               }
               alert(msg);
            });
      },
      [dispatch, history, state.userId, state.userPw]
   );

   const handleChange = function (event) {
      setState({ ...state, [event.target.name]: event.target.value });
   };

   const handleKeyPress = (event) => {
      if (event.key === "Enter") {
         handleSubmit();
      }
   };

   return (
      <div className="wrapper-login-div">
         <div className="login-div">
            <img src={logo} width="200px" alt="InBody"></img>
            <hr />
            {/* Login */}
            <div>
               <Typography variant="h6">과제업무</Typography>
               {query.get("session") && (
                  <span className={classes.sessionOut}>{"세션이 만료되었습니다. 다시 로그인해주세요."}</span>
               )}

               <TextField
                  id="userId"
                  label="아이디"
                  name="userId"
                  type="text"
                  variant="outlined"
                  value={state.userId}
                  className={classes.top}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
               ></TextField>
               <TextField
                  id="userPw"
                  name="userPw"
                  label="비밀번호"
                  type="password"
                  variant="outlined"
                  value={state.userPw}
                  className={classes.bottom}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
               ></TextField>
               <Button
                  name="button"
                  variant="contained"
                  style={{ backgroundColor: "#8a1c22", color: "white" }}
                  type="submit"
                  className={classes.button}
                  onClick={handleSubmit}
               >
                  로그인
               </Button>
            </div>
         </div>
      </div>
   );
}

export default Login;
