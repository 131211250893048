import React, { useState, useCallback } from "react";
import {
   TableCell,
   Button,
   DialogTitle,
   RadioGroup,
   FormControlLabel,
   Dialog,
   DialogContent,
   Radio,
   DialogActions,
   FormControl,
} from "@material-ui/core";
import DetailScoresService from "../systems/DetailScoresService";

function EditableScoreCell({ data, isEdit, projectId, year, index, changeScores }) {
   const [open, _setOpen] = useState(false);
   const [score, setScore] = useState(data.score || 0);
   const [defaultVal, setDefaultVal] = useState(data.score || 0);

   const setOpen = useCallback(
      (value) => {
         if (isEdit) {
            _setOpen(value);
         }
      },
      [isEdit]
   );

   const dialogOpen = () => {
      setDefaultVal(score);
      setOpen(true);
   };

   const dialogClose = () => {
      setScore(defaultVal);
      setOpen(false);
   };

   const handleChange = (e) => {
      setScore(e.target.value * 1);
   };

   React.useEffect(() => {
      if (score !== data.score) {
         setDefaultVal(data.score);
      }
   }, [data.score, score]);

   const planningUpdate = () => {
      if (data.projectId) {
         // 점수 수정
         let nextData = { ...data, score: score };
         DetailScoresService.UpdateDetailScore(nextData)
            .then((response) => {
               changeScores("update", nextData);
            })
            .catch((error) => {
               alert("점수를 수정할 수 없습니다.");
            });
      } else {
         // 점수 추가
         let _input = { projectId: projectId, year: year, month: index + 1, score: score };
         DetailScoresService.AddDetailScore(_input)
            .then((response) => {
               const res = response.data;
               changeScores("insert", {
                  projectId: res.projectId,
                  year: res.year,
                  month: res.month,
                  score: res.score,
               });
            })
            .catch((error) => {
               alert("점수를 추가할 수 없습니다.");
            });
      }
      setOpen(false);
   };

   return (
      <>
         <Dialog open={open} close={dialogClose}>
            <DialogTitle>점수 수정</DialogTitle>
            <DialogContent>
               <FormControl component="fieldset">
                  <RadioGroup aria-label="점수" name="score" value={score} onChange={handleChange}>
                     <FormControlLabel value={0} control={<Radio />} label={"0"} />
                     <FormControlLabel value={1} control={<Radio />} label={"1"} />
                     <FormControlLabel value={2} control={<Radio />} label={"2"} />
                     <FormControlLabel value={3} control={<Radio />} label={"3"} />
                     <FormControlLabel value={4} control={<Radio />} label={"4"} />
                     <FormControlLabel value={5} control={<Radio />} label={"5"} />
                  </RadioGroup>
               </FormControl>
               <DialogActions>
                  <Button onClick={planningUpdate} color="primary">
                     확인
                  </Button>
                  <Button onClick={dialogClose} color="secondary">
                     취소
                  </Button>
               </DialogActions>
            </DialogContent>
         </Dialog>
         <TableCell align="center" style={{ padding: "1px", border: "1px solid #dddddd" }} onClick={dialogOpen}>
            {data.score}
         </TableCell>
      </>
   );
}

export default EditableScoreCell;
