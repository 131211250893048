import HttpClient from "./HttpClient";

class _DetailScore {
   constructor(client) {
      this.client = client;
   }

   post(url, data) {
      return this.client.post(`/DetailScores${url}`, data);
   }
   AddDetailScore(detailScore) {
      return this.post("", detailScore);
   }
   UpdateDetailScore(detailScore) {
      return this.post(`/put/project/${detailScore.projectId}`, detailScore);
   }
}

const DetailScore = new _DetailScore(HttpClient);
export default DetailScore;
