import React from "react";

function CommonLayout(props) {
   return (
      <>
         <div className="m-4 common-wrapper">{props.children}</div>
         <hr></hr>
         <div style={{width: "100%", textAlign: "center"}}>Copyrightⓒ {new Date().getFullYear()} InBody Co., Ltd. ALL RIGHTS RESERVED.</div>
      </>
   );
}

export default CommonLayout;
