import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import ListTableHeader from "../ViewParts/WorkTableHeader";
import { Link, Paper, Button, Typography } from "@material-ui/core";
import { TABLEICONS } from "../Share/Constant";
import WorkTableRow from "../ViewParts/WorkTableRow";
import { Link as RouterLink } from "react-router-dom";
import ProjectLayout from "../Share/ProjectLayout";
import ProjectServices from "../systems/ProjectService";

function ProjectList() {
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(true);
   const [category, setCategory] = useState("");

   useEffect(() => {
      ProjectServices.GetProjectsByCategory(category)
         .then((response) => {
            //response.data
            //sort data.detailScores
            const { data } = response;
            const myData = data.map((x) => {
               //detailScores를 월 별로 정렬합니다.
               return {
                  ...x,
                  detailScores: x.detailScores.sort((x, y) => {
                     if (x.month > y.month) return 1;
                     else if (x.month < y.month) return -1;
                     return 0;
                  }),
               };
            });
            setData(myData);
         })
         .catch((error) => {
            console.log(error);
         })
         .finally(() => {
            setLoading(false);
         });
   }, [category]);

   const ClickPrint = React.useCallback(() => {
      window.open("print", "_blank");
   }, []);

   return (
      <ProjectLayout>
         <div style={{ textAlign: "center" }}>
            <h3>InBody 과제업무 리스트 {new Date().getFullYear()}</h3>
         </div>
         <div style={{ width: "100%", height: "30px" }}>
            <div style={{ float: "left", textAlign: "left", width: "50%" }}>
               <Button variant="contained" onClick={ClickPrint}>
                  전체 인쇄
               </Button>
            </div>
            <div style={{ float: "left", textAlign: "right", width: "50%" }}>
               <Link component={RouterLink} to="/write">
                  <Button variant="contained">과제 등록</Button>
               </Link>
            </div>
         </div>

         <br></br>
         {/* Row */}
         <MaterialTable
            icons={TABLEICONS}
            data={data}
            isLoading={loading}
            columns={[
               {
                  field: "title",
               },
               {
                  field: "person",
               },
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
               {},
            ]}
            components={{
               Header: (props) => {
                  return <ListTableHeader category={category} setCategory={setCategory}></ListTableHeader>;
               },
               Container: (props) => {
                  return <Paper variant="outlined">{props.children}</Paper>;
               },
               Row: ({ data }) => {
                  return <WorkTableRow data={data}></WorkTableRow>;
               },
            }}
            options={{ showTitle: false, paging: false, search: true }}
            localization={{
               body: {
                  emptyDataSourceMessage: (
                     <Typography variant="h6" style={{ fontSize: "15px" }}>
                        검색결과가 없습니다.
                     </Typography>
                  ),
               },
            }}
         ></MaterialTable>
      </ProjectLayout>
   );
}

export default ProjectList;
