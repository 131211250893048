import React, { Component } from "react";
import "./custom.css";
import { NavMenu } from "./components/NavMenu";
import { UserContextProvider, useUserDispatch } from "./systems/UserContext";
import MainRouter from "./systems/MainRouter";
import { Route, Switch } from "react-router-dom";
import Login from "./Page/Login";
import UserRoute from "./systems/UserRoute";
import UserService from "./systems/UserService";

export default class App extends Component {
   static displayName = App.name;

   //Main Router
   render() {
      return (
         <UserContextProvider>
            <MyApp></MyApp>
         </UserContextProvider>
      );
   }
}

// 이전에 로그인 했었는데, 새로고침 등으로 인해 App이 재로딩 되었다.
// token을 re-setting 해주어야 한다
function MyApp() {
   const dispatch = useUserDispatch();
   const [isInit, setIsInit] = React.useState(false);

   React.useEffect(() => {
      const savedToken = window.sessionStorage.getItem("token");

      const fetchuser = async (myToken) => {
         try {
            const response = await UserService.DecodeJwtToken(myToken);
            const { user, token } = response.data;
            UserService.SetToken(token);
            dispatch({ type: "LOGIN", item: user });
         } catch (err) {
            //로그인 필요
            //그냥 내버려두면 Login으로 이동하게 된다.
         } finally {
            setIsInit(true);
         }
      };

      if (savedToken) {
         fetchuser(savedToken);
      } else {
         //없으면 바로 실행할 수 있도록!
         setIsInit(true);
      }
   }, [dispatch]);

   if (!isInit) {
      // 아직 준비되지 않았으면 빈 화면을 보여준다.
      // 로딩 중이라고 endUser가 느낄 수 있도록
      return <div></div>;
   } else {
      return (
         <Switch>
            <Route path="/login">
               <Login></Login>
            </Route>
            <UserRoute path="/" component={Layout}></UserRoute>
         </Switch>
      );
   }
}

function Layout() {
   return (
      <>
         <NavMenu></NavMenu>
         <MainRouter></MainRouter>
      </>
   );
}
