import React, { Fragment } from "react";
//import { Viewer } from "@toast-ui/react-editor";
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";

function ToastViewer({ content }) {
   return (
      <Fragment>
         {/* <Viewer minHeight="50vh" initialValue={content}></Viewer> */}
         <span style={{ whiteSpace: "pre-line" }}>{content}</span>
      </Fragment>
   );
}

export default ToastViewer;
