import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserState } from "./UserContext";

function UserRoute({ component: Component, ...props }) {
   const state = useUserState();
   const isUser = state.employeeId !== "";

   return (
      <Route
         {...props}
         render={(props) =>
            isUser ? (
               <Component {...props} />
            ) : (
               <Redirect
                  to={{
                     pathname: "/login",
                     state: {
                        from: props.location,
                     },
                  }}
               />
            )
         }
      ></Route>
   );
}

export default UserRoute;
