import React, { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Link } from "react-router-dom";
import NoticeServices from "../systems/NoticeService";
import Collapse from "@material-ui/core/Collapse";
import FiberNewIcon from "@material-ui/icons/FiberNew";

function TopNotice({ className }) {
   const visible = true;
   const [notice, setNotice] = useState({});

   useEffect(() => {
      NoticeServices.GetTopNotice()
         .then((response) => {
            let date = response.data.created.split("T")[0].replaceAll("-", ".");
            response.data = { ...response.data, created: date };
            setNotice(response.data);
         })
         .catch((error) => {
            console.log(error);
         });
   }, []);

   return (
      <div className={className}>
         <Collapse in={visible}>
            <Alert severity="info">
               <AlertTitle>
                  <Link to={`/notice`}>
                     <strong style={{ color: "black" }}>공지사항</strong>
                  </Link>
               </AlertTitle>
               <Link to={`/notice/${notice.id}`}>
                  {notice.created} - {notice.title}
                  <FiberNewIcon style={{ fontSize: 20, marginBottom: "4px", color: "crimson" }}></FiberNewIcon>
               </Link>
            </Alert>
         </Collapse>
      </div>
   );
}

export default TopNotice;
