import { Select, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
   table: {
      "& th": {
         textAlign: "center",
         padding: "5px",
         border: "1px solid #dddddd",
         backgroundColor: "#efefef",
         fontWeight: "bold",
      },
   },
}));

function ListTableHeader({ category, setCategory }) {
   const classes = useStyles();

   const handleChange = (e) => {
      setCategory(e.target.value);
   };

   return (
      <TableHead className={classes.table}>
         <TableRow>
            <TableCell rowSpan={2} width={60}>
               순위
            </TableCell>
            <TableCell rowSpan={2} width={300}>
               개발과제명
            </TableCell>
            <TableCell rowSpan={2} width={80}>
               <Select
                  native
                  value={category}
                  onChange={handleChange}
                  style={{ fontSize: "0.875rem", fontWeight: "bold" }}
                  inputProps={{
                     name: "age",
                     id: "filled-age-native-simple",
                  }}
               >
                  <option value={""}>구분</option>
                  <option value={"개발"}>개발</option>
                  <option value={"일반"}>일반</option>
               </Select>
            </TableCell>
            {/* <TableCell rowSpan={2} width={60}>
               진도
            </TableCell> */}
            <TableCell rowSpan={2} width={70}>
               기획완료
            </TableCell>
            <TableCell rowSpan={2} width={70}>
               과제<br></br>
               성과급여
            </TableCell>
            <TableCell rowSpan={2} width={180}>
               참여인원(기여도)
            </TableCell>
            <TableCell colSpan={12} align="center">
               2021
            </TableCell>
         </TableRow>
         <TableRow>
            {[...Array(12)].map((x, i) => (
               <TableCell
                  width={23}
                  key={`tabelcell-month-${i + 1}`}
                  align="center"
                  style={{ padding: "1px", border: "1px solid #dddddd" }}
               >
                  {i + 1}
               </TableCell>
            ))}
         </TableRow>
      </TableHead>
   );
}

export default ListTableHeader;
