import React, { Fragment, useState } from "react";
import "@toast-ui/editor/dist/i18n/ko-kr";
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Button, Link, TextField } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { LINK } from "../Share/Constant";

export const ToastEditor = ({ kind, type, content, handleClick, handleCancel }) => {
   const [contents, setContents] = useState(content);

   const _handleClick = function (event) {
      handleClick(event, contents);
   };
   const _handleCancel = function (event) {
      handleCancel(event);
   };
   const handleChange = (e) => {
      setContents(e.target.value);
   };
   return (
      <Fragment>
         {/* <Editor ref={editorRef} initialEditType="wysiwyg" language="ko" initialValue={content} usageStatistics={false} height="90vh"></Editor> */}
         <TextField
            label="내용"
            value={contents}
            style={{ width: "100%" }}
            onChange={handleChange}
            rows={25}
            rowsMax={50}
            multiline={true}
            variant="outlined"
            inputProps={{ style: { fontSize: "15px", lineHeight: "150%", resize: "both" } }}
         ></TextField>
         <div style={{ textAlign: "right", height: "125px" }} className="mt-2">
            <div style={{ float: "left", width: "50%", textAlign: "left" }}>
               {type === "write" ? (
                  <Link component={RouterLink} to={LINK[kind]} underline="none">
                     <Button variant="contained">취소</Button>
                  </Link>
               ) : (
                  <Button onClick={_handleCancel} variant="contained">
                     취소
                  </Button>
               )}
            </div>
            <div style={{ textAlign: "right" }}>
               <Button onClick={_handleClick} variant="contained">
                  저장
               </Button>
            </div>
         </div>
      </Fragment>
   );
};
