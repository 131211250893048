import React, { useCallback, useState, useEffect } from "react";
import { TableCell, TextField, IconButton } from "@material-ui/core";
import ProjectService from "../systems/ProjectService";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { WIDTH } from "../Share/Constant";

function EditableCell({ name, value, data, align, isEdit, view }) {
   const [isView, _setIsView] = useState(view);
   const [updateValue, setUpdateValue] = useState(value);
   const [defaultVal, setDefaultVal] = useState(value);

   useEffect(() => {
      setUpdateValue(value);
      setDefaultVal(value);
      setIsView(view);
   }, [data, view]);

   const setIsView = useCallback(
      (value) => {
         if (isEdit) {
            _setIsView(value);
         }
      },
      [isEdit]
   );

   function handleOpen() {
      setUpdateValue(updateValue);
      setIsView(false);
   }

   const handleClose = () => {
      setUpdateValue(defaultVal);
      setIsView(true);
   };

   const handleChange = (e) => {
      setUpdateValue(e.target.value);
   };

   const handleSubmit = () => {
      var project = {
         ...data,
         comments: [],
      };
      project[name] = updateValue;
      ProjectService.EditProjectById(data.id, project)
         .then((response) => {
            setDefaultVal(updateValue);
         })
         .catch((error) => {
            setUpdateValue(defaultVal);
            alert("수정할 수 없습니다.");
         });
      setIsView(true);
   };

   if (isView) {
      return (
         <>
            <TableCell align={align} onClick={handleOpen}>
               {updateValue}
            </TableCell>
         </>
      );
   } else {
      return (
         <>
            <TableCell align={align}>
               <form onSubmit={handleSubmit}>
                  {name === "person" ? (
                     <TextField
                        name={name}
                        value={updateValue}
                        onChange={handleChange}
                        multiline={true}
                        rows={3}
                        style={{ width: `${WIDTH[name]}` }}
                        inputProps={{ style: { fontSize: "13px" } }}
                        autoFocus
                     ></TextField>
                  ) : (
                     <TextField
                        name={name}
                        value={updateValue}
                        onChange={handleChange}
                        style={{ width: `${WIDTH[name]}` }}
                        inputProps={{ style: { fontSize: "13px" } }}
                        autoFocus
                     ></TextField>
                  )}

                  <IconButton onClick={handleSubmit} size="small">
                     <CheckIcon></CheckIcon>
                  </IconButton>
                  <IconButton onClick={handleClose} size="small">
                     <CloseIcon></CloseIcon>
                  </IconButton>
               </form>
            </TableCell>
         </>
      );
   }
}

export default EditableCell;
