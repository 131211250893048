import React, { useCallback, useState, useEffect } from "react";
import {
   TableCell,
   Button,
   DialogTitle,
   RadioGroup,
   FormControlLabel,
   Dialog,
   DialogContent,
   Radio,
   DialogActions,
   FormControl,
} from "@material-ui/core";
import Planning from "./Planning";
import ProjectService from "../systems/ProjectService";

function EditableCategoryCell({ data, isEdit }) {
   const [open, _setOpen] = useState(false);
   const [category, setCategory] = useState(data.category);
   const [defaultVal, setDefaultVal] = useState(data.category);

   useEffect(() => {
      setCategory(data.category);
      setDefaultVal(data.category);
   }, [data]);

   const setOpen = useCallback(
      (value) => {
         if (isEdit) {
            _setOpen(value);
         }
      },
      [isEdit]
   );

   const dialogOpen = () => {
      setDefaultVal(category);
      setOpen(true);
   };

   const dialogClose = () => {
      setCategory(defaultVal);
      setOpen(false);
   };

   const handleChange = (e) => {
      setCategory(e.target.value);
   };

   const categoryUpdate = () => {
      ProjectService.EditProjectById(data.id, {
         ...data,
         category: category,
         comments: [],
      })
         .then((response) => {
            setDefaultVal(category);
         })
         .catch((error) => {
            setCategory(defaultVal);
            alert("수정할 수 없습니다.");
         });
      setOpen(false);
   };

   return (
      <>
         <Dialog open={open} close={dialogClose}>
            <DialogTitle>과제 구분 수정</DialogTitle>
            <DialogContent>
               <FormControl component="fieldset">
                  <RadioGroup aria-label="구분" name="category" value={category} onChange={handleChange}>
                     <FormControlLabel value={"개발"} control={<Radio />} label="개발" />
                     <FormControlLabel value={"일반"} control={<Radio />} label="일반" />
                  </RadioGroup>
               </FormControl>
               <DialogActions>
                  <Button onClick={categoryUpdate} color="primary">
                     확인
                  </Button>
                  <Button onClick={dialogClose} color="secondary">
                     취소
                  </Button>
               </DialogActions>
            </DialogContent>
         </Dialog>
         <TableCell align="center" onClick={dialogOpen}>
            {category}
         </TableCell>
      </>
   );
}

export default EditableCategoryCell;
