import HttpClient from "./HttpClient";

class _UserService {
   constructor(client) {
      this.client = client;
   }

   /**
    * 로그인 API
    * @param {string} id
    * @param {string} password
    */
   Login(id, password) {
      return this.client.post("/user/login", { id: id, password: password });
   }

   /**
    * 로그아웃 API
    */
   Logout() {
      return this.client.post("/user/logout");
   }

   _ResponseInterceptor(error) {
      if (error.response) {
         if (error.response.status === 401) {
            //Unauthorized
            window.location.replace(`${window.location.origin}/login?session=expired`);
         }
      }

      return Promise.reject(error);
   }

   /**
    * Bearer 토큰 생성
    * @param {string} token
    */
   SetToken(token) {
      if (token && token.length >= 1) {
         window.sessionStorage.setItem("token", token);
         this.client.interceptors.request.use(
            (config) => {
               config.headers["Authorization"] = `Bearer ${token}`;
               return config;
            },
            (error) => Promise.reject(error)
         );

         this.client.interceptors.response.use((config) => config, this._ResponseInterceptor);
      } else {
         throw new Error("ArgumentException token");
      }
   }

   /**
    * Bearer 토큰 무효화
    */
   DisableToken() {
      window.sessionStorage.removeItem("token");
      this.instance.interceptors.request.use(
         (config) => config,
         (error) => Promise.reject(error)
      );

      this.instance.interceptors.response.eject(this._ResponseInterceptor);
   }

   DecodeJwtToken(token) {
      return this.client.post("/user/decode", `"${token}"`, {
         headers: { "Content-Type": "application/json" },
      });
   }
}

const UserService = new _UserService(HttpClient);
export default UserService;
