import React, { Fragment, useState, useEffect } from "react";
import { ToastEditor } from "../components/ToastEditor";
import ToastViewer from "../components/ToastViewer";
import { PropTypes } from "prop-types";
import { EndTextField } from "../components/EndTextField";
import {
   Divider,
   Paper,
   Snackbar,
   Table,
   TableBody,
   TableCell,
   TableRow,
   Typography,
   IconButton,
   TextField,
} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ListIcon from "@material-ui/icons/List";
import ProjectService from "../systems/ProjectService";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { ImgLarge, LINK } from "../Share/Constant";
import { makeStyles } from "@material-ui/core/styles";
import EditableCell from "../components/EditableCell";
import EditablePlanningCell from "../components/EditablePlanningCell";
import CommentList from "../components/CommentList";
import { useHistory } from "react-router-dom";
import NoticeService from "../systems/NoticeService";
import MyButton from "../components/MyButton";
import { ErrorHandler } from "../systems/HttpClient";
import { useUserState } from "../systems/UserContext";
import { IsWriter } from "../systems/Helper";

const useStyles = makeStyles((theme) => ({
   table: {
      "& td": {
         textAlign: "center",
         padding: "10px 5px 10px 5px",
         border: "1px solid #dddddd",
      },
   },
}));
function DocumentDetail({ data, type, kind }) {
   const { noticeId, projectId } = useParams();
   const user = useUserState();
   const isRepresentative = user.name === "차기철" || user.name === "이라미";

   const classes = useStyles();
   const history = useHistory();

   const [isView, setIsView] = useState(type === "view");
   const [isWriter, setIsWriter] = useState(false);
   const [category, setCategory] = useState("");
   const [title, setTitle] = useState("");
   const [content, setContent] = useState("");
   const [project, setProject] = useState([]);
   const [person, setPerson] = useState("");
   const [grade, setGrade] = useState("");

   const [alerts, setAlerts] = useState({ openAlert: false });

   useEffect(() => {
      if (kind === "project") {
         setCategory("과제업무");
         if (type !== "write") {
            ProjectService.GetProjectById(projectId)
               .then((response) => {
                  setTitle(response.data.title);
                  setContent(response.data.content);
                  setProject(response.data);
                  setPerson(response.data.person);
                  setGrade(response.data.grade);
                  let val = IsWriter(response.data.person, user.name);
                  if (val !== isWriter) {
                     setIsWriter(val);
                  }
               })
               .catch((error) => {
                  console.log(error);
               });
         }
      } else if (kind === "notice") {
         setCategory("공지사항");
         if (type !== "write") {
            NoticeService.GetNotice(noticeId)
               .then((response) => {
                  setTitle(response.data.title);
                  setContent(response.data.content);
               })
               .catch((error) => {
                  console.log(error);
               });
         }
      }
   }, [kind, type, noticeId, projectId, user.name, isWriter]);

   const AlertSuccess = (message) => {
      setAlerts({ openAlert: true, severity: "success", message: message });
   };

   const AlertError = (message) => {
      setAlerts({ openAlert: true, severity: "error", message: message });
   };

   const UpsertNotice = async (type, newContent) => {
      let success = false;
      if (type === "write") {
         try {
            const response = await NoticeService.AddRule(title, newContent);
            history.push(`/notice/${response.data.id}`);
            success = true;
         } catch (e) {}
      } else {
         //수정
         try {
            await NoticeService.EditRule({ id: noticeId, title: title, content: content });
            success = true;
         } catch (e) {}
      }

      return success;
   };

   const UpsertProject = async (type, newContent) => {
      let success = false;
      if (type === "write") {
         try {
            const response = await ProjectService.AddProject({
               title: title,
               content: newContent,
               person: user.name,
            });
            history.push(`/${response.data.id}`);
            setProject({ ...response.data, person: [user.name] });
            success = true;
         } catch (e) {}
      } else {
         //수정
         try {
            const response = await ProjectService.EditProjectById(project.id, {
               ...project,
               person: person,
               grade: grade,
               title: title,
               content: newContent,
            });
            setProject({ ...response.data, person: response.data.person });
            success = true;
         } catch (e) {}
      }
      return success;
   };

   const handleClick = async function (event, content) {
      let success = false;
      let msg = "";

      // validation check
      if (title === null || title.length === 0) {
         alert("제목을 입력해주세요.");
      } else {
         if (kind === "notice") {
            msg += "공지사항";
            success = await UpsertNotice(type, content);
         } else if (kind === "project") {
            msg += "과제업무";
            success = await UpsertProject(type, content);
         }

         switch (type) {
            case "write":
               msg += " 작성을";
               break;
            default:
               //"edit"
               msg += " 수정을";
               break;
         }

         if (success) {
            msg += " 성공하였습니다.";
            AlertSuccess(msg);
            // editor 작성 내용 바인딩
            setContent(content);
         } else {
            msg += " 실패하였습니다.";
            AlertError(msg);
         }
         //성공하면 View로 간다.
         //실패하면 Edit 페이지에 그대로 있어야 한다.
         setIsView(success);
      }
   };

   const handleCancel = function (event) {
      setIsView(true);
   };

   const handleValueChange = function handleValueChange(name, value) {
      if (name === "title") {
         setTitle(value);
      } else if (name === "person") {
         setPerson(value);
      } else if (name === "grade") {
         setGrade(value);
      }
   };

   const handleEdit = React.useCallback(() => {
      setIsView(!isView);
   }, [isView]);

   const handleDelete = React.useCallback(async () => {
      if ((kind === "notice" || kind === "project") && window.confirm("삭제하시겠습니까?")) {
         try {
            if (kind === "notice") {
               await NoticeService.DeleteRule(noticeId);
            } else if (kind === "project") {
               await ProjectService.DeleteProject(projectId);
            }
            // 성공
            alert("성공했습니다.");
            // 다른페이지로 이동
            if (kind === "notice") {
               history.push("/notice");
            } else {
               history.push("/list");
            }
         } catch (error) {
            const msg = ErrorHandler();
            alert(msg);
         }
      }
   }, [history, kind, noticeId, projectId]);

   const handleCloseAlert = (event, reason) => {
      if (reason === "clickway") {
         return;
      }
      setAlerts({ ...alerts, openAlert: false });
   };

   return (
      <Fragment>
         <Snackbar
            open={alerts.openAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={2000}
            onClose={handleCloseAlert}
         >
            <Alert onClose={handleCloseAlert} severity={alerts.severity}>
               {alerts.message}
            </Alert>
         </Snackbar>
         <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <h2>{category}</h2>
         </div>

         <br></br>
         <div className="printArea">
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
               {isView ? (
                  <>
                     <div className="titleArea">
                        <IconButton aria-label="목록" component={RouterLink} to={LINK[kind]} className="printNone">
                           <ListIcon />
                        </IconButton>
                        <Typography variant="h5" component="span" style={{ marginBottom: "5px" }}>
                           {title}
                        </Typography>
                        <div style={{ float: "right", width: "20%", textAlign: "right" }} className="printNone">
                           <IconButton aria-label="인쇄" onClick={() => window.print()}>
                              <PrintIcon />
                           </IconButton>
                        </div>
                     </div>
                     <Divider></Divider>
                  </>
               ) : (
                  <>
                     <EndTextField
                        label="제목"
                        name="title"
                        endAdornment=""
                        error={null}
                        errorMsg={null}
                        value={title}
                        onChange={handleValueChange}
                        style={{ width: "100%" }}
                        multiline={false}
                     ></EndTextField>
                  </>
               )}
            </div>
            {kind === "project" && (
               <>
                  {project.person !== undefined && (
                     <Paper elevation={0} style={{ padding: "3px", marginBottom: "10px" }}>
                        <Table className={classes.table}>
                           <colgroup>
                              <col width="100px"></col>
                              <col width="300px"></col>
                              <col width="80px"></col>
                              <col width="140px"></col>
                              <col width="80px"></col>
                              <col width="140px"></col>
                              <col width="80px"></col>
                              <col width="140px"></col>
                           </colgroup>
                           <TableBody>
                              <TableRow>
                                 <TableCell style={{ fontWeight: "bold", backgroundColor: "#efefef" }}>
                                    참여인원<br></br>(기여도)
                                 </TableCell>
                                 <TableCell>
                                    {isView ? (
                                       <>
                                          <span>{person}</span>
                                       </>
                                    ) : (
                                       <>
                                          <EndTextField
                                             name="person"
                                             endAdornment=""
                                             error={null}
                                             errorMsg={null}
                                             value={person}
                                             onChange={handleValueChange}
                                             style={{ width: "100%" }}
                                             multiline={true}
                                          ></EndTextField>
                                       </>
                                    )}
                                 </TableCell>
                                 {/* <TableCell style={{ fontWeight: "bold", backgroundColor: "#efefef" }}>진도</TableCell>
                                 <EditableCell
                                    name="progress"
                                    value={project.progress || 0}
                                    data={project}
                                    isEdit={isRepresentative}
                                    align="center"
                                 ></EditableCell> */}
                                 <TableCell style={{ fontWeight: "bold", backgroundColor: "#efefef" }}>
                                    기획완료
                                 </TableCell>
                                 <EditablePlanningCell
                                    data={project}
                                    isEdit={isRepresentative}
                                    size={ImgLarge}
                                 ></EditablePlanningCell>
                                 <TableCell style={{ fontWeight: "bold", backgroundColor: "#efefef" }}>
                                    과제<br></br>성과급여
                                 </TableCell>
                                 <TableCell>
                                    {isView ? (
                                       <>
                                          <>
                                             <span>{grade}</span>
                                          </>
                                       </>
                                    ) : (
                                       <>
                                          <EndTextField
                                             name="grade"
                                             endAdornment=""
                                             error={null}
                                             errorMsg={null}
                                             value={grade}
                                             onChange={handleValueChange}
                                             style={{ width: "100%" }}
                                             multiline={false}
                                          ></EndTextField>
                                       </>
                                    )}
                                 </TableCell>
                              </TableRow>
                           </TableBody>
                        </Table>
                     </Paper>
                  )}
               </>
            )}
            {isView ? (
               <>
                  {content !== "" ? (
                     <div style={{ padding: "10px" }}>
                        <Paper elevation={1} style={{ padding: "10px", marginBottom: "10px" }} variant="outlined">
                           <ToastViewer content={content}></ToastViewer>
                        </Paper>
                     </div>
                  ) : (
                     <div style={{ padding: "10px" }}>
                        <Paper elevation={1} style={{ padding: "10px", marginBottom: "10px" }} variant="outlined">
                           <i>내용이 없습니다.</i>
                        </Paper>
                     </div>
                  )}
               </>
            ) : (
               <ToastEditor
                  kind={kind}
                  type={type}
                  content={content}
                  handleCancel={handleCancel}
                  handleClick={handleClick}
               ></ToastEditor>
            )}
         </div>

         {isView && (
            <Footer
               isProject={kind === "project"}
               isEdit={isRepresentative || isWriter}
               projectId={projectId}
               handleDelete={handleDelete}
               handleEdit={handleEdit}
            ></Footer>
         )}
      </Fragment>
   );
}

DocumentDetail.PropType = {
   data: PropTypes.string,
   type: PropTypes.string,
};

export default DocumentDetail;

function CommentWrapper({ projectId }) {
   return (
      <>
         <div style={{ padding: "10px" }}>
            <CommentList id={projectId}></CommentList>
         </div>
         <Divider></Divider>
      </>
   );
}

const Footer = React.memo(({ isProject, isEdit, projectId, handleDelete, handleEdit }) => (
   <>
      {isProject && <CommentWrapper projectId={projectId}></CommentWrapper>}
      {isEdit && (
         <div style={{ textAlign: "right", height: "125px" }} className="pt-2">
            <MyButton onClick={handleDelete} type="danger">
               삭제
            </MyButton>
            <MyButton onClick={handleEdit} type="edit">
               수정
            </MyButton>
         </div>
      )}
   </>
));
