import { Button, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
   danger: {
      backgroundColor: "crimson",
      color: "white",
      "&:hover": {
         backgroundColor: "#9a0e2a",
      },
      "&:active": {
         boxShadow: "none",
         backgroundColor: "#9a0e2a",
      },
   },
   edit: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      "&:hover": {
         backgroundColor: theme.palette.primary.dark,
      },
      "&:active": {
         boxShadow: "none",
         backgroundColor: theme.palette.primary.dark,
      },
   },
   root: {
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      display: "inline-block",
   },
}));

function MyButton({ type, ...rest }) {
   const classes = useStyles();
   return (
      <div className={classes.root}>
         <Button className={`${classes[type]}`} variant="contained" {...rest}></Button>
      </div>
   );
}

export default MyButton;
