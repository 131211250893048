import React, { useEffect, useState, Fragment } from "react";
import MaterialTable from "material-table";
import { TABLEICONS } from "../Share/Constant";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import NoticeServices from "../systems/NoticeService";
import { Button } from "@material-ui/core";

function NoticeList() {
   const [data, setData] = useState([]);

   useEffect(() => {
      NoticeServices.GetNotices()
         .then((response) => {
            setData(response.data);
         })
         .catch((error) => {
            console.log(error);
         });
   }, []);

   return (
      <Fragment>
         <div style={{ textAlign: "center" }}>
            <h3>공지사항</h3>
         </div>
         <div style={{ textAlign: "right" }}>
            <Link component={RouterLink} to="/notice/write" underline="none">
               <Button variant="contained">공지사항 등록</Button>
            </Link>
         </div>
         <br></br>
         <MaterialTable
            icons={TABLEICONS}
            data={data}
            columns={[
               {
                  title: "글 번호",
                  field: "id",
                  width: null,
                  headerStyle: { textAlign: "center", maxWidth: 150, width: 150, fontWeight: "bolder" },
                  cellStyle: { textAlign: "center", maxWidth: 150, width: 150 },
                  render: (rowData) => <div>{data.length * 1 - rowData.tableData.id * 1}</div>,
               },
               {
                  title: "제목",
                  field: "title",
                  width: null,
                  headerStyle: { textAlign: "center", maxWidth: 500, width: 500, fontWeight: "bolder" },
                  cellStyle: { textAlign: "left", maxWidth: 500, width: 500 },
                  render: (rowData) => (
                     <Link component={RouterLink} to={`/notice/${rowData.id}`} underline="none">
                        {rowData.title}
                     </Link>
                  ),
               },
               {
                  title: "작성일",
                  field: "created",
                  width: null,
                  headerStyle: { textAlign: "center", maxWidth: 200, width: 200, fontWeight: "bolder" },
                  cellStyle: { textAlign: "center", maxWidth: 200, width: 200 },
                  render: (rowData) => <div>{rowData.created.split("T")[0]}</div>,
               },
            ]}
            options={{ showTitle: false, pageSize: 10 }}
         ></MaterialTable>
      </Fragment>
   );
}

export default NoticeList;
