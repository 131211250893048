import React from "react";
import Signature from "../images/signature.png";
import Signature2 from "../images/signature2.png";

export default function Planning({ planning, size }) {
   if (planning === 1) {
      return <img src={Signature} width={size} alt="InBody"></img>;
   } else if (planning === 2) {
      return <img src={Signature2} width={size} alt="InBody"></img>;
   } else {
      return "-";
   }

   // else if(planning === 2){
   //    return <ChangeHistoryIcon size="small"></ChangeHistoryIcon>
   // }else if(planning === 3){
   //    return <CloseIcon size="small"></CloseIcon>
   // }else{
   //    return "-"
   // }
}
