import React from "react";
import { Container } from "@material-ui/core";
import { Redirect, Route, Switch } from "react-router-dom";
import DocumentDetail from "../ViewParts/DocumentDetail";
import ProjectList from "../Page/ProjectList";
import Notice from "../Page/Notice";

// 메인 라우터보다 상위 라우터는 App.js 에 있습니다.
function MainRouter() {
   return (
      <Container>
         <Switch>
            <Route path="/notice">
               <Notice></Notice>
            </Route>
            <Route path="/write">
               <DocumentDetail type="write" kind="project" content={""}></DocumentDetail>
            </Route>
            <Route path="/list" exact>
               <ProjectList></ProjectList>
            </Route>
            <Route path="/:projectId">
               <DocumentDetail type="view" kind="project"></DocumentDetail>
            </Route>
            <Route path="/:projectId">
               <DocumentDetail type="edit" kind="project"></DocumentDetail>
            </Route>
            <Route path="/" exact>
               <Redirect to={{ pathname: "/list" }} />
            </Route>
         </Switch>
      </Container>
   );
}

export default MainRouter;
