import React, { useState, useEffect } from "react";
import { TableCell, TableRow, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { ImgSmall, YEAR_SETTING } from "../Share/Constant";
import { makeStyles } from "@material-ui/core/styles";
import EditableCell from "../components/EditableCell";
import EditableCategoryCell from "../components/EditableCategoryCell";
import EditablePlanningCell from "../components/EditablePlanningCell";
import EditableScoreCell from "../components/EditableScoreCell";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import { useUserState } from "../systems/UserContext";
import { IsWriter } from "../systems/Helper";

const useStyles = makeStyles(() => ({
   table: {
      "& td": {
         padding: "10px 2px 10px 2px",
         border: "1px solid #dddddd",
      },
   },
}));

function WorkTableRow({ data }) {
   const [scores, setScores] = useState([]);
   const user = useUserState();
   const isWriter = IsWriter(data.person, user.name);
   const isRepresentative = user.name === "차기철" || user.name === "이라미";
   const classes = useStyles();

   useEffect(() => {
      //월별 점수
      let array = [];
      let dataIndex = 0;
      const detailScores = data.detailScores;
      for (let i = 0; i < 12; i++) {
         if (detailScores.length > dataIndex && detailScores[dataIndex].month === i + 1) {
            array.push(detailScores[dataIndex]);
            dataIndex += 1;
         } else {
            array.push({});
         }
      }

      setScores(array);
   }, [data]);

   const changeScores = (type, item) => {
      //item :  {projectId: 13, year: 2021, month: 4, score: 2}
      if (type === "update") {
         setScores(
            scores.map((x) =>
               x.month === item.month
                  ? {
                       ...x,
                       score: item.score,
                    }
                  : x
            )
         );
      } else if (type === "insert") {
         setScores(
            scores
               .slice(0, item.month - 1) //idx번째를 포함하지 않는 것
               .concat(item) // idx 번째를 채울 대신할 obj
               .concat(scores.slice(item.month)) // (idx 다음부터 끝까지)
         );
      }
   };

   return (
      <TableRow className={classes.table} style={{ fontWeight: "bold" }}>
         <TableCell align="center">{data.ranking + 1}</TableCell>
         <TableCell align="left">
            <Link component={RouterLink} to={`/${data.id}`}>
               <>
                  <span style={{ fontWeight: data.planning === 1 ? "bold" : "normal" }}>{data.title}</span>&nbsp;
                  {!data.isRead && (
                     <FiberNewIcon style={{ fontSize: 20, marginBottom: "2px", color: "Crimson" }}></FiberNewIcon>
                  )}
               </>
            </Link>
         </TableCell>
         <EditableCategoryCell isEdit={isWriter} data={data}></EditableCategoryCell>
         {/* <EditableCell
            name="progress"
            value={data.progress || 0}
            data={data}
            align="center"
            isEdit={isRepresentative}
         ></EditableCell> */}
         <EditablePlanningCell isEdit={isRepresentative} data={data} size={ImgSmall}></EditablePlanningCell>
         <EditableCell
            name="grade"
            isEdit={isRepresentative}
            value={data.grade || 0}
            data={data}
            align="center"
            view={true}
         ></EditableCell>
         <EditableCell
            isEdit={isWriter}
            name="person"
            value={data.person}
            data={data}
            align="left"
            view={true}
         ></EditableCell>
         {scores.map((x, i) => (
            <EditableScoreCell
               data={x}
               isEdit={isRepresentative}
               year={YEAR_SETTING}
               projectId={data.id}
               index={i}
               key={`table-row-${x.Id}-${i}`}
               changeScores={changeScores}
            >
               {x.score}
            </EditableScoreCell>
         ))}
      </TableRow>
   );
}

export default WorkTableRow;
