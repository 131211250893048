import HttpClient from "./HttpClient";

class _CommentService {
   constructor(client) {
      this.client = client;
   }
   AddComment(comment) {
      return this.client.post("/comment", comment);
   }

   DeleteComment(id) {
      return this.client.post(`/comment/delete/${id}`);
   }

   UpdateComment(id, comment) {
      return this.client.post(`/comment/put/${id}`, comment);
   }
   GetCommentsByProjectId(projectId) {
      return this.client.get(`/comment/project/${projectId}`);
   }
}
const CommentService = new _CommentService(HttpClient);
export default CommentService;
