import HttpClient from "./HttpClient";

class _NoticeService {
   constructor(client) {
      this.client = client;
   }

   GetTopNotice() {
      return this.client.get("/rule/recent");
   }
   GetNotice(id) {
      return this.client.get(`/rule/${id}`);
   }
   EditRule(rule) {
      return this.client.post("/rule/put", rule);
   }
   AddRule(title, content) {
      return this.client.post("/rule", { title: title, content: content });
   }
   GetNotices() {
      return this.client.get("/rule");
   }
   DeleteRule(id) {
      return this.client.post(`/rule/delete/${id}`);
   }
}

const NoticeService = new _NoticeService(HttpClient);
export default NoticeService;
