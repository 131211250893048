import React, { createContext, useContext, useReducer } from "react";

export const InitUser = {
   employeeId: "",
   name: "",
   loginId: "",
};

//action
export const LOGIN_ACTION = "LOGIN";
export const LOGOUT_ACTION = "LOGOUT";

function Reducer(state, action) {
   switch (action.type) {
      case LOGIN_ACTION:
         return {
            ...action.item,
         };
      case LOGOUT_ACTION:
         return {
            InitUser,
         };
      default:
         throw Error("Not Implemented");
   }
}

const UserState = createContext(null);
const UserDispatch = createContext(null);

export function UserContextProvider({ children }) {
   const [state, dispatch] = useReducer(Reducer, InitUser);
   return (
      <UserDispatch.Provider value={dispatch}>
         <UserState.Provider value={state}>{children}</UserState.Provider>
      </UserDispatch.Provider>
   );
}

export function useUserState() {
   const state = useContext(UserState);
   if (!state) throw new Error("UserProvider not Found");
   return state;
}

export function useUserDispatch() {
   const dispatch = useContext(UserDispatch);
   if (!dispatch) throw new Error("UserDispatch.Provider not found");
   return dispatch;
}
