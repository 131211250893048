import React, { Component } from "react";
import { Container, Navbar, NavbarBrand } from "reactstrap";
// import { Collapse,  NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import "../Styles/NavMenu.css";

export class NavMenu extends Component {
   static displayName = NavMenu.name;

   constructor(props) {
      super(props);

      this.toggleNavbar = this.toggleNavbar.bind(this);
      this.state = {
         collapsed: true,
      };
   }

   toggleNavbar() {
      this.setState({
         collapsed: !this.state.collapsed,
      });
   }

   render() {
      return (
         <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
               <Container>
                  <NavbarBrand tag={Link} to="/" style={{ color: "#8a1c22", fontSize: "30px", fontWeight: "bold" }}>
                     <img alt="logo" src={logo} style={{ width: "120px" }}></img>
                  </NavbarBrand>
                  {/* <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>
                </NavItem>
              </ul>
            </Collapse> */}
               </Container>
            </Navbar>
         </header>
      );
   }
}
