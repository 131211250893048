import axios from "axios";

function HttpClient() {
   return axios.create({ baseURL: `${window.location.origin}/api`, withCredentials: true });
   //return axios.create({ baseURL: "https://localhost:5001/api", withCredentials: true });
}

const Client = HttpClient();

export default Client;

/**
 * 에러의 반환 값에 따라 에러메세지를 반환한다.
 * @param {AxiosError} error
 */
export function ErrorHandler(error) {
   let msg = "";
   if (error.response) {
      msg += error.response.data;
      if (msg === "") {
         //여전히 빈 상태라면...
         switch (error.response.status) {
            case 400: //Bad Request
               msg = "데이터 입력이 잘못되었습니다.";
               break;
            case 403: //Forbidden
               msg = "접근할 수 없습니다.";
               break;
            case 404: //NotFound
               msg = "표시할 데이터가 없습니다.";
               break;
            case 405: //method not allowd
               msg = "405: 접근할 수 없습니다.";
               break;
            case 500:
               msg = "서버 에러가 발생하였습니다.";
               break;
            default:
               msg = "내용을 처리할 수 없습니다.";
         }
      }
   } else if (error.request) {
      msg += "서버에서 응답이 없습니다.";
   }
   return msg;
}
