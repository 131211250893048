import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";

export function EndTextField({
   name,
   value,
   endAdornment,
   onChange,
   error,
   errorMsg,
   type,
   label,
   style,
   disabled,
   multiline,
}) {
   const handleChange = React.useCallback(
      (e) => {
         onChange(e.target.name, e.target.value);
      },
      [onChange]
   );

   return (
      <TextField
         label={label}
         disabled={disabled}
         name={name}
         style={style}
         type={type || "text"}
         helperText={error ? errorMsg : ""}
         error={error}
         value={value || ""}
         onChange={handleChange}
         multiline={multiline}
         InputProps={{ endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment> }}
      ></TextField>
   );
}
