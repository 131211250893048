import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NoticeList from "../ViewParts/NoticeList";
import DocumentDetail from "../ViewParts/DocumentDetail";
import CommonLayout from "../Share/CommonLayout";

function Notice() {
   const kind = "notice";
   let { path } = useRouteMatch();

   return (
      <CommonLayout>
         <Switch>
            <Route path={`${path}/write`}>
               <DocumentDetail type="write" kind={kind}></DocumentDetail>
            </Route>
            <Route path={`${path}/edit/:noticeId`}>
               <DocumentDetail type="edit" kind={kind}></DocumentDetail>
            </Route>
            <Route path={`${path}/:noticeId`}>
               <DocumentDetail type="view" kind={kind}></DocumentDetail>
            </Route>
            <Route path={`${path}`} exact>
               <NoticeList></NoticeList>
            </Route>
         </Switch>
      </CommonLayout>
   );
}

export default Notice;
