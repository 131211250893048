import React, { useCallback, useState, useEffect } from "react";
import {
   TableCell,
   Button,
   DialogTitle,
   RadioGroup,
   FormControlLabel,
   Dialog,
   DialogContent,
   Radio,
   DialogActions,
   FormControl,
} from "@material-ui/core";
import Planning from "../components/Planning";
import ProjectService from "../systems/ProjectService";
import { ImgLarge, ImgMedium } from "../Share/Constant";
import { useUserState } from "../systems/UserContext";

function EditablePlanningCell({ data, isEdit, size }) {
   const [open, _setOpen] = useState(false);
   const [planning, setPlanning] = useState(data.planning);
   const [defaultVal, setDefaultVal] = useState(data.planning);
   const user = useUserState();

   const showDate = size === ImgLarge && planning === 1 && data.signedDate;

   useEffect(() => {
      setPlanning(data.planning);
      setDefaultVal(data.planning);
   }, [data]);

   const setOpen = useCallback(
      (value) => {
         if (isEdit) {
            _setOpen(value);
         }
      },
      [isEdit]
   );

   const dialogOpen = () => {
      setDefaultVal(planning);
      setOpen(true);
   };

   const dialogClose = () => {
      setPlanning(defaultVal);
      setOpen(false);
   };

   const handleChange = (e) => {
      setPlanning(e.target.value * 1);
   };

   const planningUpdate = () => {
      ProjectService.EditProjectById(data.id, {
         ...data,
         signed: true,
         planning: planning,
         comments: [],
      })
         .then((response) => {
            setDefaultVal(planning);
         })
         .catch((error) => {
            setPlanning(defaultVal);
            alert("수정할 수 없습니다.");
         });
      setOpen(false);
   };

   return (
      <>
         <Dialog open={open} close={dialogClose}>
            <DialogTitle>기획 수정</DialogTitle>
            <DialogContent>
               <FormControl component="fieldset">
                  <RadioGroup aria-label="기획" name="planning" value={planning} onChange={handleChange}>
                     {user.name === "차기철" && (
                        <FormControlLabel
                           value={1}
                           control={<Radio />}
                           label={<Planning planning={1} size={ImgMedium}></Planning>}
                        />
                     )}
                     {user.name === "이라미" && (
                        <FormControlLabel
                           value={2}
                           control={<Radio />}
                           label={<Planning planning={2} size={ImgMedium}></Planning>}
                        />
                     )}
                     <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label={<Planning planning={0} size={ImgMedium}></Planning>}
                     />
                     {/* <FormControlLabel value={3} control={<Radio />} label={<Planning planning={3}></Planning>} /> */}
                  </RadioGroup>
               </FormControl>
               <DialogActions>
                  <Button onClick={planningUpdate} color="primary">
                     확인
                  </Button>
                  <Button onClick={dialogClose} color="secondary">
                     취소
                  </Button>
               </DialogActions>
            </DialogContent>
         </Dialog>
         <TableCell align="center" onClick={dialogOpen}>
            <Planning planning={planning} size={size}></Planning>
            <br></br>
            {showDate && <span>{data.signedDate.split("T")[0]}</span>}
         </TableCell>
      </>
   );
}

export default EditablePlanningCell;
