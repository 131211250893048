export const IsWriter = (people, name) => {
   if (name === "차기철" || name === "이라미") return true;
   else {
      const regex = /([가-힇]+)(?:\(\d{1,3}\))?,?/g;

      let pass = false;
      if (!people) people = "";
      let groups;
      while ((groups = regex.exec(people))) {
         if (groups.length >= 1 && groups[1] === name) {
            pass = true;
            break;
         }
      }
      return pass;
   }
};
