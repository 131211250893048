import {
   Divider,
   Grid,
   Paper,
   TextField,
   Typography,
   Button,
   IconButton,
   Dialog,
   DialogContentText,
   DialogActions,
   DialogTitle,
   DialogContent,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CommentService from "../systems/CommentService";
import { format } from "date-fns";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ErrorHandler } from "../systems/HttpClient";
import { useUserState } from "../systems/UserContext";

export default function CommentList({ id: projectId }) {
   const user = useUserState();
   const [comments, setComments] = useState([]);

   const [content, setContent] = useState("");

   const [index, setIndex] = useState(0);
   const [deleteOpen, setDeleteOpen] = useState(false);
   const [updateOpen, setUpdateOpen] = useState(false);

   useEffect(() => {
      CommentService.GetCommentsByProjectId(projectId)
         .then((response) => {
            setComments(response.data);
         })
         .catch((error) => {
            let msg = ErrorHandler(error);
            alert(msg);
         });
   }, [projectId]);

   const handleChange = (e) => {
      if (e.target.name === "content") {
         setContent(e.target.value);
      }
   };

   const handleSubmit = () => {
      if (content === "") {
         alert("내용을 입력해주세요.");
         return;
      }

      let data = { projectId: projectId, name: user.loginId, content: content };

      CommentService.AddComment(data)
         .then((response) => {
            let updateComments = [];
            updateComments.push(response.data);
            updateComments = updateComments.concat(comments);
            setComments(updateComments);
            setContent("");
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const handleEdit = (index) => {
      let comment = comments[index];
      setContent(comment.content);
      setIndex(index);
      setUpdateOpen(true);
   };

   const handleDelete = (index) => {
      setIndex(index);
      setDeleteOpen(true);
   };

   const closeDialog = () => {
      setDeleteOpen(false);
   };

   const closeEdit = () => {
      setContent("");
      setUpdateOpen(false);
   };

   const deleteComment = () => {
      const dataDelete = [...comments];
      dataDelete.splice(index, 1);
      CommentService.DeleteComment(comments[index].id)
         .then((response) => {
            setComments([...dataDelete]);
         })
         .catch((error) => {
            console.log(error);
         });
      setDeleteOpen(false);
   };

   const updateComment = () => {
      const dataUpdate = [...comments];
      dataUpdate[index] = { ...comments[index], name: user.loginId, content: content };
      setComments([...dataUpdate]);
      closeEdit();
   };

   return (
      <Paper elevation={0} style={{ padding: "10px", marginBottom: "10px" }}>
         <div style={{ marginTop: "10px", marginBottm: "20px" }}>
            <Typography variant="h6">댓글 : {comments.length}</Typography>
            <Divider></Divider>
         </div>
         <Grid container>
            <Grid item xs={2} style={{ margin: "auto", padding: "5px" }}>
               <TextField
                  name="name"
                  label="이름"
                  variant="outlined"
                  value={user.loginId}
                  style={{ width: "100%" }}
                  disabled={true}
                  onChange={handleChange}
                  inputProps={{ style: { fontSize: "13px" } }}
               ></TextField>
            </Grid>
            <Grid item xs={9} style={{ padding: "5px" }}>
               <TextField
                  name="content"
                  label="내용"
                  variant="outlined"
                  multiline={true}
                  rows={3}
                  value={content}
                  style={{ width: "100%" }}
                  onChange={handleChange}
                  inputProps={{ style: { fontSize: "13px" } }}
               ></TextField>
            </Grid>
            <Grid item xs={1} style={{ textAlign: "center", margin: "auto", padding: "5px" }}>
               <Button variant="contained" onClick={handleSubmit}>
                  등록
               </Button>
            </Grid>
         </Grid>
         <Grid container style={{ marginTop: "10px" }}>
            {comments.map((x, index) => (
               <>
                  {index > 0 && (
                     <Grid item xs={12}>
                        <div style={{ padding: "2px 20px 2px 80px" }}>
                           <Divider></Divider>
                        </div>
                     </Grid>
                  )}
                  <Grid item xs={11}>
                     <Comment comment={x} key={`comment-${x}-${index}`}></Comment>
                  </Grid>
                  <Grid item xs={1} style={{ margin: "auto" }}>
                     <Grid container>
                        <Grid item xs={6}>
                           <IconButton onClick={() => handleEdit(index)} size="small">
                              <EditIcon></EditIcon>
                           </IconButton>
                           <Dialog open={updateOpen} onClose={closeEdit} aria-labelledby="responsive-dialog-title">
                              <DialogTitle id="responsive-dialog-title">댓글 수정</DialogTitle>
                              <DialogContent>
                                 <Grid container>
                                    <Grid item xs={12} style={{ margin: "auto", padding: "5px" }}>
                                       <TextField
                                          name="name"
                                          label="이름"
                                          variant="outlined"
                                          value={user.loginId}
                                          style={{ width: "100%" }}
                                          disabled={true}
                                          onChange={handleChange}
                                          inputProps={{ style: { fontSize: "13px" } }}
                                       ></TextField>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "5px" }}>
                                       <TextField
                                          name="content"
                                          label="내용"
                                          variant="outlined"
                                          multiline={true}
                                          rows={3}
                                          value={content}
                                          style={{ width: "100%" }}
                                          onChange={handleChange}
                                          inputProps={{ style: { fontSize: "13px" } }}
                                       ></TextField>
                                    </Grid>
                                 </Grid>
                              </DialogContent>
                              <DialogActions>
                                 <Button onClick={updateComment} autoFocus>
                                    수정
                                 </Button>
                                 <Button onClick={closeEdit}>취소</Button>
                              </DialogActions>
                           </Dialog>
                        </Grid>
                        <Grid item xs={6}>
                           <IconButton onClick={() => handleDelete(index)} size="small">
                              <DeleteIcon></DeleteIcon>
                           </IconButton>
                           <Dialog open={deleteOpen} onClose={closeDialog} aria-labelledby="responsive-dialog-title">
                              <DialogTitle id="responsive-dialog-title">댓글 삭제</DialogTitle>
                              <DialogContent>
                                 <DialogContentText>삭제하시겠습니까?</DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                 <Button onClick={deleteComment} autoFocus>
                                    삭제
                                 </Button>
                                 <Button onClick={closeDialog}>취소</Button>
                              </DialogActions>
                           </Dialog>
                        </Grid>
                     </Grid>
                  </Grid>
               </>
            ))}
         </Grid>
      </Paper>
   );
}

function Comment({ comment }) {
   return (
      <div style={{ padding: "5px" }}>
         <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={9}>
               <strong>{comment.name}</strong>
            </Grid>
            <Grid item xs={2}>
               <i style={{ color: "#888888", fontSize: "14px" }}>
                  {format(new Date(comment.created), "yyyy-MM-dd HH:mm")}
               </i>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={11}>
               {comment.content}
            </Grid>
         </Grid>
      </div>
   );
}
