import HttpClient from "./HttpClient";

class _ProjectService {
   constructor(client) {
      this.client = client;
   }

   GetProjectsByCategory(category) {
      return this.client.get(`/project/${category}`);
   }

   GetProjectById(id) {
      return this.client.get(`/project/simple/${id}`);
   }

   // GetProjectDetailById(id) {
   //    return this.client.get(`/project/${id}/detail`);
   // }

   EditProjectById(id, project) {
      return this.client.post(`/project/${id}/edit/info`, project);
   }

   AddProject(project) {
      return this.client.post("/project", project);
   }

   DeleteProject(id) {
      return this.client.post(`/project/delete/${id}`);
   }
}

const ProjectService = new _ProjectService(HttpClient);
export default ProjectService;
